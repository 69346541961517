import Box from '@mui/material/Box';
import Footer from '@/components/Footer';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { InputNumber } from 'antd';
import Styles from '@/styles/index.module.scss';
import classNames from 'classnames';
import { Button as AtButton, message, theme } from 'antd';
import {
  handleChooseWallet,
  handleIsMobile,
  handleQuitDialog,
  handleLoadingDialog,
  initialLoadingDialog,
  handleCheckNet,
  handleDialog,
  initialDialog,
  handleModal,
  handleGalleryDialog,
} from '@/store/actions/wallet';
import { useEffect, useRef, useState } from 'react';
import { checkNetwork } from '@/components/CheckNet';
import abi from './mint/KEYVOXNFT.json';
import abi20 from './mint/ERC20.json';

const Home = () => {
  const isMobile = useSelector((state) => state.wallet.isMobile);
  const choice = useSelector((state) => state.wallet.choice);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const router = useRouter();
  const [quantity, setQuantity] = useState(1);
  const [alertShow, setAlertShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [maxQuantity, setMaxQuantity] = useState(1);
  const [currency, setCurrency] = useState('matic');
  const [supply, setSupply] = useState('0');
  const [singlePrice, setSinglePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mintSign, setMintSign] = useState('');
  const [errorText, setErrorText] = useState('');
  const [step, setStep] = useState(1);
  const httpContract = useSelector((state) => state.wallet.httpContract);
  const dialog = useSelector((state) => state.wallet.dialog);
  const [roadmapStep, setRoadmapStep] = useState(0);

  // console.log("isMobile", isMobile)
  // useEffect(() => {
  //   if (choice.address) {
  //     dispatch(handleLoadingDialog({ isOpen: true }));
  //     getAllPrice();
  //   }
  // }, [quantity, currency, choice.address]);

  // useEffect(() => {
  //   if (choice.address) {
  //     // getTotalSupply();
  //     getMaxBynum();
  //   }
  // }, [choice.address]);

  // const { token } = theme.useToken();
  // const globalToken = theme.getDesignToken();
  const roadmap = [
    {
      year: '2023年10月',
      desc: '项目启动',
    },
    {
      year: '2024年1月',
      desc: '社区DAO',
    },
    {
      year: '2024年3月',
      desc: 'NFT创作完成',
    },
    {
      year: '2024年5月',
      desc: '上线交易平台',
    },
    {
      year: '2024年7月',
      desc: '支持少林全球考功大赛',
    },
    {
      year: '2025年',
      desc: '元宇宙开放',
    },
  ];

  const formatNum = (num) => Math.ceil(num / Math.pow(10, 14)) / 10000;

  const getMaxBynum = async () => {
    const maxBynum = await httpContract.methods.maxBatchSize().call();
    setMaxQuantity(maxBynum);
  };
  const getAllPrice = async () => {
    let [curPrice, allPrice] = [0, 0];
    if (currency === 'matic') {
      // curPrice = await httpContract.methods.getPrice(1).call(); // 查询NTF价格 获取单价 在matic情况下
      allPrice = await httpContract.methods.getPrice(quantity).call(); // 查询NTF价格 获取总价 在matic情况下
      curPrice = allPrice / quantity;
    } else {
      curPrice = await httpContract.methods
        .getERC20Price(process.env.NEXT_PUBLIC_ERC20_ADDRESS, 1)
        .call(); // 查询NTF价格 获取单价 在ETH情况下
      allPrice = await httpContract.methods
        .getERC20Price(process.env.NEXT_PUBLIC_ERC20_ADDRESS, quantity)
        .call(); // 查询NTF价格 获取总价 在ETH情况下
    }
    setSinglePrice(curPrice);
    setTotalPrice(allPrice);
    console.log(`单个${currency} NTF价格`, curPrice / Math.pow(10, 18));
    console.log(`${currency} NTF总价`, allPrice / Math.pow(10, 18));
    dispatch(initialLoadingDialog());
  };
  const getTotalSupply = async () => {
    let totalSupply = await httpContract.methods.totalSupply().call();
    setSupply(totalSupply);
  };

  const handleBuy = async () => {
    if (choice?.address) {
      const passCheck = await checkNetwork(choice);
      if (!passCheck) {
        dispatch(handleCheckNet({ isOpen: true }));
        return;
      }
    } else {
      dispatch(handleChooseWallet({ isOpen: true }));
      return;
    }
    dispatch(
      handleDialog({
        isOpen: true,
        totalPrice: formatNum(totalPrice),
        type: currency === 'matic' ? 'ETH' : 'WETH',
        step: currency === 'matic' ? 1 : 2,
        active: 1,
        errorText,
      }),
    );
    let toAddress = process.env.NEXT_PUBLIC_NFT_ADDRESS; // 被授权地址
    let erc20Address = process.env.NEXT_PUBLIC_ERC20_ADDRESS;
    let ethContract = new choice.provider.eth.Contract(abi.abi, toAddress); // 所有代币的abi可以通用（abi,合约地址）
    let eth20Contract = new choice.provider.eth.Contract(
      abi20.abi,
      erc20Address,
    ); // 所有代币的abi可以通用（abi,合约地址）
    let fromAddress = await choice.provider.eth.getAccounts();
    let count = await ethContract.methods
      .canMintAmount(fromAddress[0], quantity)
      .call();
    if (!count) {
      // TODO 需要提示用户不能继续购买了
      dispatch(
        handleDialog({
          isOpen: true,
          totalPrice: formatNum(totalPrice),
          type: 'ETH',
          step: 1,
          active: 0,
          errorText: 'You can not buy any more!',
        }),
      );
      return;
    }
    // 1,0000,0000,0000,0000
    console.log(fromAddress);
    console.log(ethContract);
    let usermatic = await choice.provider.eth.getBalance(fromAddress[0]);
    console.log(usermatic);
    console.log(123334);
    if (currency === 'matic') {
      let balance = await ethContract.methods.getPrice(quantity).call(); // 查询NTF价格 在matic情况下
      if (Number(usermatic) < Number(balance)) {
        dispatch(
          handleDialog({
            isOpen: true,
            totalPrice: formatNum(totalPrice),
            type: 'ETH',
            step: 1,
            active: 0,
            errorText: 'You not have enough ETH!',
          }),
        );
        return;
      }
      console.log('ETH NTF价格', balance / Math.pow(10, 18));
      // let gasp = await ethContract.methods
      //   .mintToMultiple(fromAddress[0], quantity, mintSign)
      //   .estimateGas({ from: fromAddress[0], value: balance }); // matic 总价
      // console.log(gasp); // 93209
      // 弹框 matic
      let balance3;
      try {
        balance3 = await ethContract.methods
          .mintToMultiple(fromAddress[0], quantity)
          .send({
            from: fromAddress[0],
            value: balance,
            gas: 500000,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          });
        console.log('balance3', balance3);
        if (balance3.status) {
          dispatch(initialDialog());
          setTimeout(() => {
            dispatch(
              handleModal({
                isOpen: true,
                title: t('mint.success.title'),
                content: t('mint.success.description'),
                btnText: t('mint.success.buttontext'),
                jumpUrl: '/collection',
              }),
            );
          }, 1000);
        }
      } catch (e) {
        console.log(e.message.split(':')[1]);
        console.log(dialog);
        dispatch(
          handleDialog({
            isOpen: true,
            totalPrice: formatNum(totalPrice),
            type: 'ETH',
            step: 1,
            active: 0,
            errorText: e.message.split(':')[1],
          }),
        );
      }
    } else {
      // ETH
      // getAllowanceAsync(); // 钱包余额 balanceETH 如果大于有余额 ==> 完成您的新发 否则两步
      // 钱包余额 balanceETH 如果小于余额  getApprove 充钱
      // 再查 getAllowanceAsync()
      // 付款

      const pay = async () => {
        // let gasp = await ethContract.methods
        //   .mintToMultipleERC20(
        //     fromAddress[0],
        //     quantity,
        //     process.env.NEXT_PUBLIC_ERC20_ADDRESS,
        //     mintSign,
        //   )
        //   .estimateGas({ from: fromAddress[0] });
        // console.log(gasp); // 93209
        let balance3 = await ethContract.methods
          .mintToMultipleERC20(
            fromAddress[0],
            quantity,
            process.env.NEXT_PUBLIC_ERC20_ADDRESS,
            mintSign,
          )
          .send({
            from: fromAddress[0],
            gas: 500000,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          });
        console.log('callback', balance3);
        if (balance3.status) {
          dispatch(initialDialog());
          dispatch(
            handleModal({
              isOpen: true,
              title: t('mint.success.title'),
              content: t('mint.success.description'),
              btnText: t('mint.success.buttontext'),
              jumpUrl: '/collection',
            }),
          );
        }
      };
      const balanceETH = await ethContract.methods
        .getERC20Price(process.env.NEXT_PUBLIC_ERC20_ADDRESS, quantity)
        .call(); // 查询NTF价格 在ETH情况下
      const ethBalance = await getEthBlanceAsync();
      const allowance = await getAllowanceAsync();
      console.log('查询NTF价格', balanceETH);
      console.log('查询余额', allowance);
      if (Number(balanceETH) > Number(allowance)) {
        if (Number(ethBalance) < Number(balanceETH)) {
          dispatch(
            handleDialog({
              isOpen: true,
              totalPrice: formatNum(totalPrice),
              type: 'WETH',
              step: 2,
              active: 0,
              errorText: 'You not have enough ETH!',
            }),
          );
          return;
        }
        // 小狐狸账户
        try {
          let fromAddress = await choice.provider.eth.getAccounts();
          const ethBalance = await eth20Contract.methods
            .approve(toAddress, balanceETH + '')
            .send({
              from: fromAddress[0],
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
            });
          dispatch(
            handleDialog({
              isOpen: true,
              totalPrice: formatNum(totalPrice),
              type: 'WETH',
              step: 2,
              active: 1,
              errorText: '',
            }),
          );
          if (ethBalance.status) {
            dispatch(
              handleDialog({
                isOpen: true,
                totalPrice: formatNum(totalPrice),
                type: 'WETH',
                step: 2,
                active: 2,
                errorText: '',
              }),
            );
            await pay();
          }
        } catch (e) {
          dispatch(
            handleDialog({
              isOpen: true,
              totalPrice: formatNum(totalPrice),
              type: 'MATIC',
              step: 2,
              active: 0,
              errorText: e.message.split(':')[1],
            }),
          );
        }
      } else {
        dispatch(
          handleDialog({
            isOpen: true,
            totalPrice: formatNum(totalPrice),
            type: 'WETH',
            step: 1,
            active: 1,
            errorText: '',
          }),
        );
        if (Number(ethBalance) < Number(balanceETH)) {
          setAlertShow(true);
          setErrorMsg('You not have enough ETH!');
          // setDialog(false);
          return;
        }
        try {
          await pay();
        } catch (e) {
          dispatch(
            handleDialog({
              isOpen: true,
              totalPrice: formatNum(totalPrice),
              type: 'MATIC',
              step: 1,
              active: 0,
              errorText: e.message.split(':')[1],
            }),
          );
        }
      }

      console.log(balanceETH - allowance > 0);
      console.log('单个ETH NTF价格', balanceETH / Math.pow(10, 18));
    }
  };

  const getAllowanceAsync = async () => {
    let ethContract = new choice.provider.eth.Contract(
      abi20.abi,
      process.env.NEXT_PUBLIC_ERC20_ADDRESS,
    ); // 所有代币的abi可以通用（abi,合约地址）
    let fromAddress = await choice.provider.eth.getAccounts();
    let paymentPrice = await ethContract.methods
      .allowance(fromAddress[0], process.env.NEXT_PUBLIC_NFT_ADDRESS)
      .call();
    return paymentPrice;
  };
  const getEthBlanceAsync = async () => {
    let ethContract = new choice.provider.eth.Contract(
      abi20.abi,
      process.env.NEXT_PUBLIC_ERC20_ADDRESS,
    ); // 所有代币的abi可以通用（abi,合约地址）
    let fromAddress = await choice.provider.eth.getAccounts();
    let paymentPrice = await ethContract.methods
      .balanceOf(fromAddress[0])
      .call();
    return paymentPrice;
  };

  const generateRandomNumbers = () => {
    var randomNumbers = [];
    for (var i = 0; i < 10; i++) {
      var randomNumber = Math.floor(Math.random() * 300) + 1;
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  };

  const imgTopDom = useRef(null);
  const imgBottomDom = useRef(null);

  const [timer, setTimer] = useState(null);
  const [randomNumbers, setRandomNumbers] = useState({
    top: [],
    bottom: [],
    gallery: [],
  });

  function moveImages() {
    if (!imgTopDom.current || !imgBottomDom.current) {
      cancelAnimationFrame(timer);
      return;
    }

    let imgTop = imgTopDom.current;
    let imgBottom = imgBottomDom.current;

    let w = 10 * 118;

    let ll = imgTop.style.right ? parseFloat(imgTop.style.right) : 0.1;
    let rr = imgBottom.style.left ? parseFloat(imgBottom.style.left) : 0.1;
    // console.log("first", l, r)

    imgTop.style.right = `${ll + 0.1}px`;
    imgBottom.style.left = `${rr + 0.1}px`;


    if (ll === w) {
     
      imgTop.style.right = `${0.1}px`;
   
    }

    if (rr === w) {
     
      imgBottom.style.left = `${0.1}px`;
     
    }

    setTimer(requestAnimationFrame(moveImages));
  }

  // moveImages()

  useEffect(() => {

    if (imgTopDom.current && imgBottomDom.current) {
      let t = generateRandomNumbers()
      let b = generateRandomNumbers()
      let g = generateRandomNumbers()

      setRandomNumbers({
        top: [...t, ...t],
        bottom: [...b, ...b],
        gallery: g,
      })

      moveImages();
    }
    return () => {
      // console.log("dsdasdsadsadsadsa")
      timer && cancelAnimationFrame(timer);
    };
  }, [imgTopDom, imgBottomDom, isMobile]);

  return (
    <>
      <div className="bg-black">
        <div className="text-shaolinMainColor w-mainWidth items-center mx-auto pb-[104px] 2xl:relative max-md:mx-[1.5rem] max-md:w-auto max-md:pb-[1.5rem]">
          <div className="h-[781px] w-[1046px] top-[-100px] 2xl:left-[274px] 2xl:right-[unset] right-0 absolute z-0 max-md:w-[15rem] max-md:h-[18.2rem] max-md:top-[5rem]">
            <video
              className="max-md:h-[15rem]"
              autoPlay={true}
              loop={true}
              muted={true}
              webkit-playsinline="true"
              playsInline={true}
            >
              <source src="/static/indexmainbg.mp4" type="video/mp4"></source>
            </video>
          </div>
          <div className="h-[697px] sticky z-10 max-md:h-[15.47rem]">
            {/* opensea */}
            {false && (
              <>
                <div className="pt-[258px] text-[112px] font-shaolin max-md:pt-[0.94rem] max-md:text-[28px]">
                  KungFu BODY
                </div>
                <div className="w-[512px] text-[24px] mt-[32px] font-shaolin max-md:text-[10px] max-md:w-[178px] max-md:mt-[0.56rem]">
                  The Book Will Not Be Lost And You Win To- Gether - Lou Books
                </div>
                <div className="mt-[44px] max-md:mt-[0.75rem]">
                  <AtButton
                    type="primary"
                    className="!bg-white !text-black !text-[24px] mr-[24px] !h-[64px] !px-[24px] !rounded-[8px] max-md:!text-[12px] max-md:!h-[2rem] max-md:!px-[0.5rem] max-md:!rounded-[0.25rem] max-md:mr-[0.81rem]"
                    onClick={() => {
                      window.open(
                        'https://opensea.io/collection/kungfuloong',
                        '_blank',
                      );
                    }}
                  >
                    BUY IN OPENSEA
                  </AtButton>
                  <AtButton
                    className="!text-white !border-1 !border-white !text-[24px] !h-[64px] !pl-[24px] !pr-[24px] !rounded-[8px] max-md:!text-[12px] max-md:!h-[2rem] max-md:!px-[0.5rem] max-md:!rounded-[0.25rem]"
                    type="text"
                    onClick={() => {
                      window.open(
                        'https://www.okx.com/cn/web3/marketplace/nft/collection/eth/kungfuloong',
                        '_blank',
                      );
                    }}
                  >
                    BUY IN OKX
                  </AtButton>
                </div>
              </>
            )}
            {/* mint */}
            {!!choice.address && (
              <>
                <div className="pt-[258px] text-[112px] font-shaolin max-md:pt-[0.94rem] max-md:text-[28px]">
                  KungFu BODY
                </div>
                <div className="mt-[44px]">
                  <AtButton
                    type="primary"
                    className="!bg-white !text-black !text-[24px] !h-[64px] !px-[48px] !rounded-[8px] max-md:!text-[12px] max-md:!h-[2rem] max-md:!px-[1.5rem] max-md:!rounded-[0.25rem] max-md:mr-[0.81rem]"
                    onClick={() => {
                      router.push('/collection');
                    }}
                  >
                    我的收藏
                  </AtButton>
                </div>
                {/* <div>total supply {supply}</div>
              <div>max quantity {maxQuantity}</div>
              <div>quantity {quantity}</div>
              <div>single price {formatNum(singlePrice)}</div>
              <div>total price {formatNum(totalPrice)}</div> */}
                {/* <div className="mt-[32px] max-md:mt-[0.56rem]">
                <div className="flex flex-row justify-start">
                  <div className="w-[96px] text-[16px] text-shaolinGray1 leading-[40px] max-md:w-[4rem] max-md:text-[14px] max-md:leading-[1.6rem]">单价</div>
                  <div className="text-[#32FFB6] text-[32px] font-[700] max-md:text-[22px] max-md:leading-[1.6rem]">{formatNum(singlePrice)} ETH</div>
                </div>
                <div className="flex flex-row justify-start mt-[16px] max-md:mt-[0.28rem]">
                  <div className="w-[96px] text-[16px] text-shaolinGray1 leading-[40px] max-md:w-[4rem] max-md:text-[14px]">数量</div>
                  <div>
                      <InputNumber onChange={(v) => {
                        // message.info(`input number changed ${v}`)
                        setQuantity(v)
                      }} min={1} max={maxQuantity} size="middle" defaultValue={1} />
                  </div>
                </div>
              </div>
              <div className="mt-[72px] text-[32px] font-[500] max-md:text-[24px] max-md:mt-[1.5rem]">
                总价: {formatNum(totalPrice)} ETH + Gas
              </div>
              <div className="mt-[24px] max-md:mt-[0.5rem]">
                <AtButton type="primary" className="!bg-white !text-black !text-[24px] !h-[64px] !px-[164px] !rounded-[8px] max-md:!text-[12px] max-md:!h-[2rem] max-md:!w-full max-md:!rounded-[0.25rem] max-md:mr-[0.81rem]" onClick={() => {
                  handleBuy()
                }}>铸造</AtButton>
              </div> */}
              </>
            )}
            {/* connection */}
            {!choice.address && (
              <>
                <div className="pt-[258px] text-[112px] font-shaolin max-md:pt-[0.94rem] max-md:text-[28px]">
                  KungFu BODY
                </div>
                <div className="mt-[44px]">
                  <AtButton
                    type="primary"
                    className="!bg-white !text-black !text-[24px] !h-[64px] !px-[48px] !rounded-[8px] max-md:!text-[12px] max-md:!h-[2rem] max-md:!px-[1.5rem] max-md:!rounded-[0.25rem] max-md:mr-[0.81rem]"
                    onClick={() => {
                      if (choice.address) {
                        dispatch(handleQuitDialog({ isOpen: true }));
                      } else {
                        dispatch(handleChooseWallet({ isOpen: true }));
                      }
                    }}
                  >
                    我的收藏
                  </AtButton>
                </div>
              </>
            )}
          </div>
          <div className="mt-[52px] sticky z-10 font-shaolin text-[48px] text-left max-md:mt-[1.53rem] max-md:text-[20px]">
            项目背景
          </div>
          <div className="mt-[32px] text-[16px] leading-[24px] sticky z-10 max-md:text-[14px] max-md:mt-[1rem]">
            功夫是东方文化的身体语言，功夫体是体悟我心与宇宙合一的绝妙法门。
            KUNGFU BODY
            主体是灵动精炼的线条构成的人形，运动如中国书法的“提按、绞转”，方与圆
            ，刚与柔，顶天立地。取其精髓，创造独特的“功夫朋克”未来数字美学。
            每一张图片背景蕴含经典的东方文化内涵，彰显了“丰富多元”的东方人文精神世界。{' '}
            <br />
            <br />
            KUNGFU BODY
            数字文创的主体内容包含“少林易筋经十二式”，外加八个特色内容：无畏法印、达摩面壁、水月观音、紧那罗王、单臂合十、猛龙出海、太极云手、少林禅拳。
            背景的不同材质体现典型东方文化特色，包括儒释道文化典型文字、中国二十四节气、青铜、玉石、漆器、五正色等。
          </div>
        </div>
      </div>
      <div className="bg-[#F7FBFE] text-shaolinBlack relative">
        {/* 铸造属于你的kungfu loong */}
        <div className="w-mainWidth h-[635px] mx-auto 2xl:relative max-md:mx-[1rem] max-md:w-auto max-md:h-[19.25rem]">
          <div className="h-[938px] w-[755px] top-[-105px] 2xl:left-[501px] 2xl:right-[unset] right-0 absolute bg-[url('/static/nft-bg.png')] bg-no-repeat z-0 max-md:bg-[url('/static/nft-bg-mobile.png')] max-md:w-[16.25rem] max-md:h-[21.68rem] max-md:top-[-1.2rem] max-md:bg-repeat-round"></div>
          <div className="sticky pt-[100px] w-[333px] font-shaolin text-[48px] text-shaolinBlack max-md:pt-[1.5rem] max-md:text-[24px] max-md:w-[200px]">
            铸造属于你的{' '}
            <span className="text-[#F9AF00] max-md:text-[32px]">
              KungFu BODY
            </span>
          </div>
          <div className="sticky mt-[32px] text-[14px] leading-[32px] text-shaolinGray max-md:mt-[0.75rem] max-md:w-[200px]">
            九大系列的龙图腾，赋予你九中不同的神秘力量，训练他们，完成终极的使命
          </div>
          <div className="w-[501px] h-[1px] bg-shaolinGray/[0.15] max-md:w-[200px]"></div>
          <div className="mt-[16px] w-[501px] flex flex-row justify-between max-md:mt-[0.5rem] max-md:w-[200px]">
            <div>
              <div className="text-shaolinGray text-[16px] max-md:text-[14px]">
                背景
              </div>
              <div className="text-shaolinBlack text-[24px] mt-[8px] max-md:text-[18px] max-md:mt-[0.25rem]">
                25种
              </div>
            </div>
            <div>
              <div className="text-shaolinGray text-[16px] max-md:text-[14px]">
                动作
              </div>
              <div className="text-shaolinBlack text-[24px] mt-[8px] max-md:text-[18px] max-md:mt-[0.25rem]">
                20种
              </div>
            </div>
            <div>
              <div className="text-shaolinGray text-[16px] max-md:text-[14px]">
                材质
              </div>
              <div className="text-shaolinBlack text-[24px] mt-[8px] max-md:text-[18px] max-md:mt-[0.25rem]">
                20种
              </div>
            </div>
          </div>
        </div>
        {/* nft权益 */}
        <div className="w-mainWidth mx-auto relative max-md:mx-[1.5rem] max-md:w-auto">
          <div className="absolute top-[35px] left-[-35px] w-[1202px] h-[67px] bg-[url('/static/hr.svg')] bg-no-repeat max-md:w-[22.3rem] max-md:h-[2.4rem] max-md:bg-repeat-round max-md:left-[-0.6rem] max-md:top-[0.3rem]"></div>
          <div className="font-shaolin text-[48px] text-shaolinBlack max-md:text-[16px]">
            NFT权益
          </div>
        </div>
        <div className="w-headWidth mx-auto mb-[98px] max-md:mx-[1rem] max-md:w-auto">
          <div className="mt-[76px] flex flex-row justify-between max-md:mt-[1.5rem] max-md:flex-col">
            <div className="bg-white rounded-[8px] w-[383px] max-md:w-full">
              <div className="h-[199px] bg-[url('/static/t1.png')] bg-no-repeat bg-center mt-[16px] max-md:mt-[0.5rem] max-md:h-[12rem] bg-contain"></div>
              <div className="mx-[16px] mt-[16px]">
                <div className="text-[20px] font-[500] font-shaolin">
                  KUNGFU BODY 质押收益
                </div>
                <div className="mt-[12px] text-[16px] font-[500] text-shaolinGray">
                  用户可将NFT质押到平台，获取稳定的项目收益。
                </div>
              </div>
              <div className="text-center my-[20px]">
                {/* <AtButton type="primary" className="!h-[40px] !bg-black !text-white !rounded-[4px] !px-[103px] !py-[9px] !text-[14px] !font-[500]">立即查看</AtButton> */}
              </div>
            </div>
            <div className="bg-white rounded-[8px] w-[383px] max-md:w-full max-md:mt-[1rem]">
              <div className="h-[199px] bg-[url('/static/t2.png')] bg-no-repeat bg-center mt-[16px] max-md:mt-[0.5rem] max-md:h-[12rem] bg-contain"></div>
              <div className="mx-[16px] mt-[16px]">
                <div className="text-[20px] font-[500] font-shaolin">
                  KUNGFU BODY 实体权益
                </div>
                <div className="mt-[12px] text-[16px] font-[500] text-shaolinGray">
                  用户可获得线下参与少林功夫文化中心课程的权益。中心自创立以来秉承“德武结合，文武双修”的核心理念，致力于将少林功夫这一国粹传承并发扬光大，为功夫爱好者提供最正宗、最专业的少林功夫学习与体验。
                </div>
              </div>
              <div className="text-center my-[20px]">
                {/* <AtButton type="primary" className="!h-[40px] !bg-black !text-white !rounded-[4px] !px-[103px] !py-[9px] !text-[14px] !font-[500]">立即查看</AtButton> */}
              </div>
            </div>
            <div className="bg-white rounded-[8px] w-[383px] max-md:w-full max-md:mt-[1rem]">
              <div className="h-[199px] bg-[url('/static/t3.png')] bg-no-repeat bg-center mt-[16px] max-md:mt-[0.5rem] max-md:h-[12rem] bg-contain"></div>
              <div className="mx-[16px] mt-[16px]">
                <div className="text-[20px] font-[500] font-shaolin">
                  KUNGFU BODY 元宇宙权益
                </div>
                <div className="mt-[12px] text-[16px] font-[500] text-shaolinGray">
                  只有项目NFT的用户可获得少林数字元宇宙居民身份，拥有元宇宙禅武虚境的优先体验权，并作为首批居民享有特定空投权益。
                </div>
              </div>
              <div className="text-center my-[20px]">
                {/* <AtButton type="primary" className="!h-[40px] !bg-black !text-white !rounded-[4px] !px-[103px] !py-[9px] !text-[14px] !font-[500]">立即查看</AtButton> */}
              </div>
            </div>
          </div>
          <div className="mt-[160px] flex flex-row justify-start max-md:mt-[4.8rem] max-md:flex-col">
            <div className="w-[505px] h-[505px] mr-[68px] bg-[url('/static/metaversebg.png')] bg-no-repeat max-md:w-full max-md:mr-0 max-md:h-[22.4rem] max-md:bg-repeat-round bg-contain"></div>
            <div className="w-[619px] max-md:w-auto">
              <div className="text-[52px] font-[700] leading-[normal] max-md:text-[24px] max-md:text-center max-md:mt-[1.5rem] font-shaolin">
                KungFu BODY 数实融合
              </div>
              <div className="mt-[6px] text-[20px] leading-[normal] max-md:text-[16px]">
                <div>
                  在新时代新需求的引领下，传统体育文化行业原有业态将融合新一代基础设施能力进行创新升级。在全新的沉浸式交互媒介之上，构建虚实融合的功夫元宇宙新业态。线下建设VR元宇宙馆，在虚拟世界体验功夫场景。平台将逐步开发传统文体数字产品AR“试穿”“试用”等功能，通过生动、多维的3D可视化商品展现和交互形式，为消费者提供更具“临场感”的功夫体衍生产品购物体验。
                </div>
                <br />
                <div>
                  KUNGFU
                  BODY作为传统体育文化的新生代衍生经典IP，也在牢牢抓住新技术变量带来的机会窗口，基于实体武馆和实物商品构建虚实融合的沉浸式交互体验，将数字世界的效率、便捷与线下消费的体验、时效在同一场景内打通，满足数字原住民追求“极致效率”和“个性体验”的消费需求。
                </div>
              </div>
              <div className="mt-[32px] max-md:mt-[0.75rem] max-md:text-center">
                {/* <AtButton type="primary" className="!h-[46px] !bg-black !text-white !rounded-[4px] !px-[24px] !py-[8px] !text-[20px] !font-[500]">查看更多</AtButton> */}
              </div>
            </div>
          </div>
        </div>
        {/* Gallery */}
        <div className="w-mainWidth mx-auto relative max-md:mx-[1.5rem] max-md:w-auto">
          <div className="absolute top-[25px] left-[-35px] w-[1202px] h-[67px] bg-[url('/static/hr.svg')] bg-no-repeat max-md:w-[22.3rem] max-md:h-[1.8rem] max-md:bg-repeat-round max-md:left-[-0.6rem] max-md:top-[0.3rem]"></div>
          <div className="font-shaolin text-[48px] text-shaolinBlack max-md:text-[16px] flex justify-between items-center">
            <span>Gallery</span>
            <span
              className="text-[16px] max-md:text-[10px] z-10 cursor-pointer"
              onClick={() => {
                router.push('/gallery');
              }}
            >
              了解更多 →{' '}
            </span>
          </div>
        </div>

        <div className="mx-auto h-[160px] relative overflow-hidden md:hidden">
          <div
            className="w-full absolute top-[20px] color-[#000000] flex justify-start right-[0px]"
            id="imgTop"
            ref={imgTopDom}
          >
            {randomNumbers.top.map((item, i) => (
              <div
                key={i}
                className="w-[100px] h-[135px] p-[4px]  bg-white rounded-[4px] mx-[5px]"
              >
                <Box
                  component="img"
                  src={`${
                    process.env.NEXT_PUBLIC_IPFS.indexOf('.png') !== -1
                      ? process.env.NEXT_PUBLIC_IPFS
                      : process.env.NEXT_PUBLIC_IPFS + '/' + item + '.png'
                  }`}
                  className="w-[100px] bg-no-repeat rounded-[4px] aspect-[1/1]"
                ></Box>
                <div className="w-full mt-[5px] color-[#152545] !text-[8px]">
                  {/* Casoandra */}
                  KungFuBody
                </div>
                <div className="w-full flex justify-between !text-[9px]">
                  <div className="flex items-end">#{item}</div>
                  <div className="">
                    <AtButton
                      type="primary"
                      className="!bg-black !text-white !text-[8px] !rounded-[2px] !py-[0px] !px-[6px] !m-[0px] !h-[15px]"
                      onClick={() => {
                        dispatch(
                          handleGalleryDialog({
                            toNFT: item,
                            active: null,
                            isOpen: true,
                          }),
                        );
                        router.push('/gallery');
                      }}
                    >
                      查看
                    </AtButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto h-[170px] relative overflow-hidden md:hidden">
          <div
            className="w-full absolute top-[8px] color-[#000000] flex flex-row-reverse left-[0px]"
            id="imgBottom"
            ref={imgBottomDom}
          >
            {randomNumbers.bottom.map((item, i) => (
              <div
                key={i}
                className="w-[100px] h-[135px] p-[4px]  bg-white rounded-[4px] mx-[5px]"
              >
                <Box
                  component="img"
                  src={`${
                    process.env.NEXT_PUBLIC_IPFS.indexOf('.png') !== -1
                      ? process.env.NEXT_PUBLIC_IPFS
                      : process.env.NEXT_PUBLIC_IPFS + '/' + item + '.png'
                  }`}
                  className="w-[100px] bg-no-repeat rounded-[4px] aspect-[1/1]"
                ></Box>
                <div className="w-full mt-[5px] color-[#152545] !text-[8px]">
                  {/* Casoandra */}
                  KungFuBody
                </div>
                <div className="w-full flex justify-between !text-[9px]">
                  <div className="flex items-end">#{item}</div>
                  <div className="">
                    <AtButton
                      type="primary"
                      className="!bg-black !text-white !text-[8px] !rounded-[2px] !py-[0px] !px-[6px] !m-[0px] !h-[15px]"
                      onClick={() => {
                        dispatch(
                          handleGalleryDialog({
                            toNFT: item,
                            active: null,
                            isOpen: true,
                          }),
                        );
                        router.push('/gallery');
                      }}
                    >
                      查看
                    </AtButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-mainWidth mx-auto h-[400px] relative max-md:w-auto max-md:hidden">
          <div className="absolute w-full top-[60px] color-[#000000] flex justify-between overflow-hidden overflow-x-scroll">
            {randomNumbers.gallery.map((item, i) => (
              <div
                key={i}
                className="w-[200px] h-[270px] p-[10px]  bg-white rounded-[8px] mr-[10px]"
              >
                <Box
                  component="img"
                  src={`${
                    process.env.NEXT_PUBLIC_IPFS.indexOf('.png') !== -1
                      ? process.env.NEXT_PUBLIC_IPFS
                      : process.env.NEXT_PUBLIC_IPFS + '/' + item + '.png'
                  }`}
                  className="block w-[200px] bg-no-repeat rounded-[8px] aspect-[1/1]"
                />
                <div className="w-full mt-[12px] color-[#152545]">
                  {/* Casoandra */}
                  KungFuBody
                </div>
                <div className="w-full flex justify-between">
                  <div className="flex items-end">#{item}</div>
                  <div className="">
                    <AtButton
                      type="primary"
                      className="!bg-black !text-white !text-[12px] !rounded-[4px]"
                      onClick={() => {
                        dispatch(
                          handleGalleryDialog({
                            toNFT: item,
                            active: null,
                            isOpen: true,
                          }),
                        );
                        router.push('/gallery');
                      }}
                    >
                      查看
                    </AtButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Roadmap */}

        <div className="w-mainWidth mx-auto relative max-md:mx-[1.5rem] max-md:w-auto md:hidden">
          <div className="absolute top-[25px] left-[-35px] w-[1202px] h-[67px] bg-[url('/static/hr.svg')] bg-no-repeat max-md:w-[22.3rem] max-md:h-[1.8rem] max-md:bg-repeat-round max-md:left-[-0.6rem] max-md:top-[0.3rem]"></div>
          <div className="font-shaolin text-[48px] text-shaolinBlack max-md:text-[16px]">
            Roadmap
          </div>
        </div>

        <div className="mx-auto h-[705px] overflow-hidden mt-[20px] px-[20px] pb-[20px] relative flex md:hidden">
          <div className="absolute top-0 left-0 w-[18px] h-[650px] relative flex flex-col justify-between">
            {roadmap.map((item, index) => (
              <div className="relative w-[18px] h-[18px]" key={index}>
                <div className="absolute top-[5px] left-[5px] w-[8px] h-[8px] bg-[url('/static/dot-small.svg')] bg-no-repeat"></div>
                <div className="absolute top-0 left-0 w-[18px] h-[18px] bg-[url('/static/dot-big.svg')] bg-no-repeat"></div>
              </div>
            ))}
            <div className="w-[2px] h-[635px] bg-[#2F2F2F] absolute left-[8px] top-[9px] opacity-10"></div>
          </div>
          <div className="absolute top-[0px] left-[45px] w-full h-[668px] flex flex-col justify-between">
            {roadmap.map((item, index) => (
              <div className="" key={index}>
                <p className="text-[14px]">{item.year}</p>
                <p className="text-[12px]">{item.desc}</p>
              </div>
            ))}
          </div>

          {/* <div className="w-auto h-full bg-[url('/static/dot-big.svg')] bg-no-repeat"></div> */}
        </div>
        <div className="w-mainWidth mx-auto relative text-shaolinBlack pb-[100px] max-md:mx-[1.5rem] max-md:w-auto max-md:hidden">
          <div className="absolute top-[25px] left-[-35px] w-[1202px] h-[67px] bg-[url('/static/hr.svg')] bg-no-repeat"></div>
          <div className="font-shaolin text-[48px] text-shaolinBlack">
            Roadmap
          </div>
          <div className="absolute right-[20px] top-[126px]">
            <AtButton
              onClick={() => {
                if (roadmapStep <= 0) {
                  setRoadmapStep(0);
                } else {
                  setRoadmapStep(roadmapStep - 1);
                }
              }}
              className="mr-[12px] !bg-[url('/static/larray.svg')] !bg-no-repeat !w-[32px] !rounded-none"
              type="text"
            ></AtButton>
            <AtButton
              onClick={() => {
                if (roadmapStep >= roadmap.length - 1) {
                  setRoadmapStep(roadmap.length - 1);
                } else {
                  setRoadmapStep(roadmapStep + 1);
                }
              }}
              className="!bg-[url('/static/rarray.svg')] !bg-no-repeat !w-[32px] !rounded-none"
              type="text"
            ></AtButton>
          </div>
          <div className="mt-[75px] text-[24px] text-center">
            {roadmap[roadmapStep].year}
          </div>
          <div className="mt-[8px] text-[16px] text-center">
            {roadmap[roadmapStep].desc}
          </div>
          <div>
            <div className="ml-[40px] mt-[20px] w-[1070px] h-[4px] bg-shaolinBlack opacity-10 rounded-[5px]"></div>
            <div className="text-center mt-[16px] flex flex-row justify-between text-[24px] text-shaolinBlack">
              {roadmap.map((item, index) => {
                return (
                  <div className="relative w-[82px]" key={index}>
                    {item.year}
                    <div
                      style={{
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                      }}
                      className="absolute top-[-22px] h-[8px] w-[8px] bg-[url('/static/dot-small.svg')] bg-no-repeat"
                    ></div>
                    {roadmapStep >= index && (
                      <div
                        style={{
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                        }}
                        className="absolute top-[-27px] h-[18px] w-[18px] bg-[url('/static/dot-big.svg')] bg-no-repeat"
                      ></div>
                    )}
                    {roadmapStep > index && (
                      <div
                        style={{
                          width: `${1260 / roadmap.length}px`,
                        }}
                        className="absolute left-[40px] top-[-20px] h-[4px] bg-shaolinBlack"
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? 'jp', ['common'])),
  },
});

export default Home;
